// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-tag-js": () => import("../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-author-js": () => import("../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-index-js": () => import("../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-speed-js": () => import("../src/pages/speed.js" /* webpackChunkName: "component---src-pages-speed-js" */),
  "component---src-pages-test-js": () => import("../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

